<template>
  <div class="pageWrap">
    <buycourse
      :courseData="courseData"
      :userCourseStatus="userCourseStatus"
      :courseVideo="videoSource"
    />
    <otherSection :courseData="courseData" />
    <courseBreakdown
      :courseData="courseData"
      :userCourseStatus="userCourseStatus"
    />
    <review :courseData="courseData" />

    <addreview :courseData="courseData" />
    <recommend />
  </div>
</template>
<script>
import axios from "axios";
import api from "@/services/api";
import Helper from "@/services/helper";
import store from "@/store";

import buycourse from "./partials/buycourse.vue";
import otherSection from "./partials/otherSection.vue";
import courseBreakdown from "./partials/courseBreakdown.vue";
import review from "./partials/review.vue";
import addreview from "./partials/addreview.vue";
import recommend from "./partials/recommend.vue";

export default {
  name: "CourseDetail",
  components: {
    buycourse,
    otherSection,
    courseBreakdown,
    review,
    addreview,
    recommend,
  },
  data() {
    return {
      isAuthenticated: store.getters.authenticated,
      user_id: store.getters.user.id,
      userCourseStatus: [],
      page_slug: this.$route.params.slug,
      id: this.$route.params.id,
      courseData: [],
      videoSource: "",
    };
  },
  mounted() {
    let app = this;
    app.renderDetail();
    app.renderStatus();
    app.renderVideo();
  },
  methods: {
    async renderDetail() {
      let app = this;
      Helper.showSpinner();
      await axios
        .get(api.getUrl("/course/" + app.page_slug + "/" + app.id))
        .then(function (response) {
          if (response.data.success) {
            document.title = response.data.data.title;
            app.courseData = response.data.data;
          }
        })
        .finally(() => {
          Helper.hideSpinner();
        });
    },
    async renderStatus() {
      let app = this;
      if (app.isAuthenticated) {
        await axios
          .get(api.getUrl("/user/" + app.user_id + "/" + app.id + "/status"))
          .then(function (response) {
            app.userCourseStatus = response.data.data;
          });
      }
    },
    async renderVideo() {
      let app = this;
      const response = await axios.post(api.getUrl("/course/video"), {
        courseId: app.id,
      });
      // const blob = response.data.url;

      app.videoSource = response.data.url;
      // app.videoSource = URL.createObjectURL(blob);
    },
  },
  created() {
    let app = this;
    app.$watch(
      () => app.$route.query,
      () => {
        app.page_slug = app.$route.params.slug;
        app.id = app.$route.params.id;
        app.renderDetail();
        app.renderStatus();
        app.renderVideo();
      }
    );
  },
};
</script>
