<template>
  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="title-breakdown">
            <h2>Course Breakdown</h2>
          </div>
        </div>
        <div class="col-12">
          <div class="course-breakDown faq-wrap">
            <div
              class="breakDown-Chapter"
              v-if="Array.isArray(curriculums) && curriculums.length > 0"
            >
              <div
                class="course-data-wrappper"
                v-for="(curriculum, i) in curriculums"
                :key="i"
              >
                <div class="lesson-list-wrapper">
                  <div class="lesson-count sticky-course">
                    <p class="mb-1">Curriculum {{ i + 1 }}</p>
                    <h3>{{ curriculum.title }}</h3>
                    <img
                      :src="curriculum.image"
                      class="img-fluid"
                      :alt="curriculum.title"
                    />
                  </div>
                </div>
                <div
                  class="lesson-detail-wrapper"
                  v-if="curriculum.lessons.length > 0"
                >
                  <!-- <div class="accordion">
                    <b-card
                      no-body
                      class="mb-3"
                      v-for="(lesson, j) in curriculum.lessons"
                      :key="j"
                    >
                      <b-card-header header-tag="header">
                        <b-button
                          block
                          v-b-toggle="'accordion-' + lesson.lesson_id"
                          class="d-block w-100"
                          variant="info"
                        >
                          <span>{{ lesson.title }}</span>
                          <span class="icon-down"></span>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        :id="'accordion-' + lesson.lesson_id"
                        visible
                        accordion="my-accordion"
                      >
                        <b-card-body>
                          <b-card-text>{{ lesson.description }}</b-card-text>
                          <b-card-text>
                            <template v-if="isAuthenticated">
                              <div ref="playButton"
                                v-if="
                                  lesson.authz_status == '1' || courseStatus
                                "
                              >
                                <router-link
                                  :to="{
                                    name: 'LessonVideos',
                                    params: {
                                      course: courseId,
                                      curriculum: curriculum.curriculum_id,
                                      lesson: lesson.lesson_id,
                                    },
                                  }"
                                  class="playBtn-course"
                                >
                                  <span class="icon-video"></span> Play
                                </router-link>
                              </div>
                              <div ref="playButton" v-else>
                                <a
                                  @click="buyCartItem(cartItem)"
                                  class="playBtn-course"
                                  ><span class="icon-video"></span> Play
                                </a>
                              </div>
                            </template>
                            <template v-else>
                              <a v-b-modal.authenticate class="playBtn-course">
                                <span class="icon-video"></span> Play</a
                              >
                            </template>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div> -->
                  <SingleChapter
                    v-for="(lesson, index) in curriculum.lessons"
                    :key="index"
                    :lesson="lesson"
                    :courseId="courseId"
                    :curriculum="curriculum"
                    :courseStatus="courseStatus"
                    :cartItem="cartItem"
                    @childPlayClicked="handleChildPlayClicked"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import store from "@/store";
// import { mapActions } from "vuex";
import SingleChapter from "./SingleChapter.vue";
export default {
  name: "courseBreakdown",
  props: {
    courseData: {
      required: true,
      type: [Array, Object],
    },
    userCourseStatus: {
      required: true,
      type: [Array, Object],
    },
  },
  data() {
    return {
      isAuthenticated: store.getters.authenticated,
      userId: store.getters.user.id,
      courseStatus: this.userCourseStatus.p_status,
      courseEstatus: this.userCourseStatus.e_status,
      courseId: this.courseData.course_id,
      curriculums: this.courseData.course_curriculums,
      videoData: "",
      cartItem: {
        user_id: store.getters.user.id,
        item_id: this.courseData.course_id,
        item_type: "course",
        quantity: 1,
      },
      clickedLesson: "",
    };
  },
  mounted() {
    let app = this;
    app.$root.$on("authentication-status", ($event) => {
      app.isAuthenticated = $event;
    });
  },
  methods: {
    // ...mapActions(["buyCartItem"]),
    handleChildPlayClicked(clickedLesson) {
      let app = this;
      app.clickedLesson = clickedLesson;
    },
  },
  watch: {
    courseData(value) {
      let app = this;
      app.courseId = value.course_id;
      app.curriculums = value.course_curriculums;
      app.cartItem.item_id = value.course_id;
    },
    userCourseStatus(value) {
      let app = this;
      app.courseStatus = value.p_status;
      app.courseEstatus = value.e_status;
    },
    isAuthenticated(value) {
      let app = this;
      if (value) {
        app.$nextTick(() => {
        let playButton = document.getElementById(
          `accordion-${app.clickedLesson.lesson_id}`
        );
        if(playButton){
        let innerPlayButton = playButton.querySelector(".playBtn-course");
        innerPlayButton.click();
        }
      });
      }
      
    },
  },
  components: { SingleChapter },
};
</script>
