<template>
  <section class="py-2">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="title-otherSection">
            <h2>About this Course</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis, quidem, id
              excepturi rerum nesciunt voluptatum animi distinctio, nulla vel esse
              blanditiis. Necessitatibus eius fugiat nobis ex rerum nemo quasi accusamus? Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis, quidem, id
              excepturi rerum nesciunt voluptatum animi distinctio, nulla vel esse
              blanditiis. Necessitatibus eius fugiat nobis ex rerum nemo quasi accusamus?
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "otherSection",
};
</script>
