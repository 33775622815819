<template>
     <section class="recommend-wrap py-5" v-if="courses.data.length > 0 || pageData" >
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="title-breakdown">
                        <h2>Recommended Courses</h2>
                    </div>
                </div>
                <div v-for="(item,i) in courses.data" :key="i" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                    <CourseItem :item="item"/>
                </div>
            </div>
        </div>
    </section>
  </template>
  <script>
  import axios from 'axios';
  import api from '@/services/api';
  import CourseItem from '@/components/CourseItem'

  export default {
    name: "recommend",
    components: {
        CourseItem
    },
    data(){
        return{
            pageData: true,
            id: this.$route.params.id,
            courses: { 
                data: [],
                links: {}, 
                meta: {}
            },
            from:"0",
            to:"0",
            total:"0",
            last_page:"",
            filterData:{
                page:1,
                limit: 4,
                keywords:"",
                level:"",
                category:"",
                sort:"latest",
            },
        }
    },
    created(){
        let app = this;
        app.$watch(() => app.$route.query,() => {
            app.id = app.$route.params.id;
            app.getRecommendedCourses();
        })
        app.getRecommendedCourses();
    },
    methods:{
        getRecommendedCourses(){
            let app = this;
            const course_id = app.id;
            axios.get(api.getUrl(`/courses/recommendedCources?course_id=${course_id}`))
            .then((response) => {
                if(response.data.success){
                    app.pageData = false;
                    let responseData = response.data.data;
                    app.courses.data = responseData.data;
                    app.from    =   responseData.meta.from;
                    app.to      =   responseData.meta.to;
                    app.total   =   responseData.meta.total;
                    app.last_page = responseData.meta.last_page;
                    app.filterData.limit = responseData.meta.per_page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }
  };
  </script>