<template>
    <div class="accordion">
      <b-card no-body class="mb-3">
        <b-card-header header-tag="header">
          <b-button block v-b-toggle="'accordion-' + lesson.lesson_id" class="d-block w-100" variant="info">
            <span>{{ lesson.title }}</span>
            <span class="icon-down"></span>
          </b-button>
        </b-card-header>
        <b-collapse :id="'accordion-' + lesson.lesson_id" visible accordion="my-accordion">
          <b-card-body>
            <b-card-text>{{ lesson.description }}</b-card-text>
            <b-card-text @click="handleClick">
              <template v-if="isAuthenticated">
                <div v-if="lesson.authz_status === '1' || courseStatus">
                  <router-link :to="{
                    name: 'LessonVideos',
                    params: {
                      course: courseId,
                      curriculum: curriculum.curriculum_id,
                      lesson: lesson.lesson_id,
                    },
                  }" class="playBtn-course">
                    <span class="icon-video"></span> Play
                  </router-link>
                </div>
                <div v-else>
                  <a @click="buyCartItem(cartItem)" class="playBtn-course">
                    <span class="icon-video"></span> Play
                  </a>
                </div>
              </template>
              <template v-else>
                <a v-b-modal.authenticate class="playBtn-course">
                  <span class="icon-video"></span> Play
                </a>
              </template>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </template>
  
  <script>
  import store from "@/store";
  import { mapActions } from "vuex";
  export default {
    props: {
      lesson: Object,
      courseId: String,
      curriculum: Object,
      courseStatus: Boolean,
      cartItem: Object
    },
    data() {
    return {
      isAuthenticated: store.getters.authenticated,
    };
  },
    mounted() {
    let app = this;
    app.$root.$on("authentication-status", ($event) => {
      app.isAuthenticated = $event;
    });
  },
    methods: {
      handleClick() {
        this.$emit('childPlayClicked', this.lesson);
      },
      ...mapActions(["buyCartItem"]),
    }
  };
  </script>
  