<template>
    <div class="py-3 review-wrap" v-if="reviewData.data.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-6 mb-3">
                    <div class="review-title mb-2">
                        <h5>Ratings</h5>
                    </div>
                    <div class="display-card">
                        <div class="feedback-wrapper mb-2">
                            <div class="review-summary">{{reviewData.course_rating.average_rating}} / 5</div>
                            <div class="feedback-star mb-2">
                                <star-rating :rating="reviewData.course_rating.average_rating" :read-only="true" :star-size="30" :show-rating="false"></star-rating>
                            </div>
                        </div>
                        <div class="feedback-rating">
                            <ul>
                                <li v-for="(data, r) in reviewData.course_rating.ratings" :key="r">
                                    <div class="progress-wrapper">
                                        <div class="progress-rating">
                                            <div class="progress-bar" :style="{ width: data.value + '%' }"></div>
                                        </div>
                                        <div class="star-rating-total">
                                            <star-rating :rating="data.rating" :read-only="true" :star-size="18" :show-rating="false"></star-rating>
                                            <div class="rating-percent">{{ data.value }}%</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-6 mb-3">
                    <div class="comment-title mb-2">
                        <h5>Reviews</h5>
                    </div>
                    <div class="display-card scroll-cmt">
                        <div class="each-comment">
                            <vue-scroll :ops="reviewScroll">
                                <div v-for="(item , key) in reviewData.data" :key="key" class="list-comment d-flex" >
                                    <div class="comment-imgholder">
                                        <img :src="item.user.avatar">
                                    </div>

                                    <div  class="comment-body">
                                        <div class="comment-meta  mb-2">
                                            <div class="cmt-author-wrapper">
                                                <div class="cmt-author-link"> {{item.user.name}} </div>
                                            </div>
                                            <div class="cmt-review-rating"> <star-rating :rating="item.rating" :read-only="true"
                                                    :star-size="18" :show-rating="false"></star-rating>
                                            </div>
                                        </div>
                                        <div v-html="item.feedback"></div>
                                    </div>
                                </div>
                            </vue-scroll>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


import StarRating from 'vue-star-rating';
import store from "@/store"
import { mapActions } from "vuex"
export default {
    name: "review",
    components: {
        StarRating
    },
    data: () => ({
        reviewScroll: {
            vuescroll: {
                mode: "native",
                wheelScrollDuration: "700",
            },
            scrollPanel: {
                maxHeight: 265,
            },
            bar: {
                background: "#ddd",
            },
        },
    }),
    created(){
        let app = this;
        app.getReviews()
    },
    computed: {
        reviewData(){
            return store.getters.getReviewState;
        }
    },
    methods:{
        ...mapActions(["getCourseReview"]),
        getReviews(){
            let app = this;
            const course_id = app.$route.params.id;
            app.getCourseReview(course_id);
        }
    }
}
</script>