<template>
    <div class="py-5 review-wrap">
        <div class="container">
            <div class="row">
                <div class="addReview">
                    <div class="add-review-rating mb-3">
                        <h5>Write a Review</h5>
                        <textarea v-model="feedback" name="" id="" class="univ-input" rows="4" placeholder="Enter your comment here..."></textarea>
                        <span class="error-field" v-if="formErrors.feedback"><span class="icon-warning"></span> {{ formErrors.feedback }}</span>
                    </div>
                    <div class="add-review-rating mb-3">
                        <h5>Course Ratings</h5>
                        <star-rating :rating="rating" v-model="rating" :read-only="false" :star-size="40" :show-rating="false"></star-rating>
                    </div>
                    <div class="form-group mt-4 d-flex justify-content-end">
                        <button v-if="isAuthenticated" @click="submitReview" class="univ-btn"> <b-spinner v-if="loader" small></b-spinner> Submit Feedback</button>
                        <button v-else v-b-modal.authenticate class="univ-btn">Submit Feedback</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating';
import store from "@/store"
import axios from "axios"
import api from "@/services/api"

export default {
    name: "addreview",
    components: {
        StarRating
    },
    data() {
        return {
            feedback: '',
            rating: 5,
            loader:false,
            formErrors: {
                feedback:"",
            },
        };
    },
    computed: {
        isAuthenticated() {
       return store.getters.authenticated;
     },
    },
    methods: {
        submitReview() {
            let app = this;
            app.clearedError();
            app.loader = true;
            axios.post(api.getUrl('/user/feedback'), {
                feedback: app.feedback,
                rating: app.rating,
                course_id : app.$route.params.id
            }).then((response) => {
                if(response.data.success == true){
                    app.loader = false;
                    app.feedback = "";
                    app.$toast.success("Successfully submitted your review. Thak you!",{position: "top-center",timeout: 2000});
                }else{
                    if(response.data.message){
                        app.loader = false;
                        app.$toast.error(response.data.message,{position: "top-center",timeout: 2000});
                    }else if(response.data.errors.length > 0){
                        app.loader = false;
                        response.data.errors.forEach(function(item) {
                            if(item.key == "feedback"){
                                app.formErrors.feedback = item.message;
                            }
                        }); 
                    }
                }
            });
        },
        resetReview() {
            let app = this;
            app.feedback = '';
            app.rating = 5;
        },
        clearedError(){
            let app = this;
            app.formErrors.feedback = "";
        },
    },
};
</script>